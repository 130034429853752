import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './EquilibriumGuide.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { ButtonType1 } from '../../components/Button/ButtonType1';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import page1 from '../../assets/images/EquilibriumGuide/99a.jpg';
import page2 from '../../assets/images/EquilibriumGuide/99b.jpg';
import page3 from '../../assets/images/EquilibriumGuide/99c.jpg';
import page4 from '../../assets/images/EquilibriumGuide/99d.jpg';
import page5 from '../../assets/images/EquilibriumGuide/220.jpg';
import page6 from '../../assets/images/EquilibriumGuide/239a.jpg';
import page7 from '../../assets/images/EquilibriumGuide/239b.jpg';

// 평형안내
const EquilibriumGuide = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isClicked, setIsClicked] = useState("전체");

  return (
    <div>
      <Header pathName={currentPath} />

      <div className={styles.container}>
        
        <div>
          <div className={styles.buttonBoxTitle}>평형 선택</div>
          <div className={styles.buttonBox}>
            <ButtonType1 title="전체" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="99A" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="99B" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="99C" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="99D" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="220" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="239A" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="239B" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
          </div>
        </div>

        <div className={styles.imageBox}>
          <ImageBoxContainer type={isClicked} />
        </div>
      </div>

      <Footer />
      <FixedButton />
      <IpBox />
    </div>
  );
};

export default EquilibriumGuide;


const ImageBoxContainer = ({ type }) => {
  if (type === "99A") {
    return (
        <img className={styles.image} src={page1} alt="99A" />
    )
  } else if (type === "99B") {
    return (
        <img className={styles.image} src={page2} alt="99B" />
    )
  } else if (type === "99C") {
    return (
        <img className={styles.image} src={page3} alt="99C" />
    )
  } else if (type === "99D") {
    return (
        <img className={styles.image} src={page4} alt="99D" />
    )
  } else if (type === "220") {
    return (
        <img className={styles.image} src={page5} alt="220" />
    )
  } else if (type === "239A") {
    return (
        <img className={styles.image} src={page6} alt="239A" />
    )
  } else if (type === "239B") {
    return (
        <img className={styles.image} src={page7} alt="239B" />
    )
  } else {
    return (
      <>
        <img className={styles.image} src={page1} alt="gogelseo-EquilibriumGuide 99A" />
        <img className={styles.image} src={page2} alt="gogelseo-EquilibriumGuide 99B" />
        <img className={styles.image} src={page3} alt="gogelseo-EquilibriumGuide 99C" />
        <img className={styles.image} src={page4} alt="gogelseo-EquilibriumGuide 99D" />
        <img className={styles.image} src={page5} alt="gogelseo-EquilibriumGuide 220" />
        <img className={styles.image} src={page6} alt="gogelseo-EquilibriumGuide 239A" />
        <img className={styles.image} src={page7} alt="gogelseo-EquilibriumGuide 239B" />
      </>
    )
  }
}
