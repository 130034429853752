import { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";

import styles from './AdvertModal.module.scss';

export const AdvertModal = ({ image, place }) => {
  const [open, setOpen] = useState(true); // 닫기버튼을 눌렀는가
  const [checked, setChecked] = useState(false); // 체크박스를 체크했는가
  const [hasCookie, setHasCookie] = useState(true); // 쿠키가 저장되어있는가
  // (초기값을 false로 하면, 쿠키가 있어도 처음에 잠깐 나타남.)
  const [cookies, setCookies] = useCookies(); // 쿠기에 저장되는 내용

  // 닫기 버튼이 click 됐을 때
  const handleClose = () => {
    setOpen(false);
    setHasCookie(true);
  };

  // 체크박스가 change 됐을 때
  const handleChange = (e) => {
    const changed = e.target.checked;
    if (changed) {
      setChecked(changed)
    }
    else setChecked(changed)
  };


  // 쿠키의 유효기한을 지정하는 함수
  const getExpiredDate = (days) => {
    const date = new Date(); // 현재 시간을 받아온다
    date.setDate(date.getDate() + days);
    // 현재시간의 날짜에 days 만큼 더하여 유효기간을 지정
    return date;
  };

  // 페이지 열면 바로 쿠키 유무 확인
  useEffect(() => {
    if (cookies[`HBB_Cookie${place}`]) {
      // HBB_Cookie라는 쿠키가 있어?
      setHasCookie(true);
    } else setHasCookie(false); // 없어?
  }, []);

  // 닫기 버튼을 누를 때마다 실행될 코드.
  useEffect(() => {
    // 체크되지 않은 상태에서 모달을 닫을 경우
    if (!checked && !open) {
      return;
    }
    // 체크된 상태에서 모달을 닫을 경우
    if (checked && !open) {
      //쿠키를 저장하는 핵심 코드
      const expires = getExpiredDate(1);
      setCookies(`HBB_Cookie${place}`, true, { path: "/", expires });
    }
  }, [open]);

  return (
    <>
      {!hasCookie && (
        <div className={[place=== 0 ? styles.modal1 : styles.modal2]} >
          <img className={styles.img} src={image} alt='광고' />

          <div className={styles.closeLine}>
            <div>
              <input type='checkbox' id='checkbox' onChange={handleChange} />
              <label htmlFor='checkbox'>오늘 하루 더이상 보지 않기</label>
            </div>

            <button className={styles.clonseBtn} type='button' onClick={handleClose}>닫기</button>
          </div>

        </div>
      )}
    </>
  );
}